.App {
  text-align: center;
  background-image: url(./4041\ 1\ \(1\).png);
background-size: cover;
background-repeat: no-repeat;
max-width: 100%;
max-height: 100%;
bottom: 0;
left: 0;
margin: auto;
overflow: auto;
position: fixed;
right: 0;
top: 0;
-o-object-fit: contain;
object-fit: contain;
}
body{


}
.topcorner{
  position:absolute;
  top:10px;
  right: 10px;

  font-weight: bolder;
}
.wallet-adapter-button{
  background-image: linear-gradient(to right, #740082, #d1006d, #ff5b49, #ffaf21, #f2ff43);
  color: white;
  border-radius: 30px !important;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.center{
  position: absolute; 
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  
}
img{
  width: 50%;
  height: 50%;
  border-radius: 30px;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.btn {
  border-radius: 30px;
  font-size: 1.2vw;
  padding: 0.9rem;
  background-image: linear-gradient(to right, #740082, #d1006d, #ff5b49, #ffaf21, #f2ff43);
  color: white;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 1.3rem;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.btn:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
@media screen and (max-width:1024px)  {
  img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .btn {
 
    font-size: 5.2vw;
  }
}